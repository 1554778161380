import vert from './bump.vert';
import frag from './bump.frag';

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createBumpCommand = (regl, img, canvas) => {
  const position = [
    [-1, 1, 0],
    [-1, -1, 0],
    [1, -1, 0],
    [1, 1, 0],
  ];

  const uv = [0, 1, 0, 0, 1, 0, 1, 1];

  const devicePixelRatio = window.devicePixelRatio;

  return regl({
    vert,
    frag,
    attributes: {
      position,
      uv,
    },
    uniforms: {
      u_time: ({ time }) => time,
      u_texture1: regl.texture({ data: img[0], flipY: true }),
      u_texture2: regl.texture({ data: img[1], flipY: true }),
      u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      u_canvasRes: [canvas.width, canvas.height],
      u_pixelRatio: devicePixelRatio,
      uResWin: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      uTexture: regl.texture({ data: img[1], flipY: true }),
    },
    count: position.length,
    primitive: 'triangle fan',
    blend: {
      enable: true,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
