<template lang="pug">
.stats.fixed
  img(ref='img' src='~@/assets/img-effect/pic-3-blur.jpg' style='display: none')
  img(ref='img1' src='~@/assets/img-effect/pic-3.jpg' style='display: none')
.canvas
  canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createBumpCommand } from '@/commands/image-effect/bump/index.js';
import createREGL from 'regl';
import { loadImg, resize } from './common';

export default {
  name: 'Bump',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const img1 = ref(null);

    let regl;

    onMounted(async () => {
      // resize(canvas.value);
      const imgRatio = 1080 / 1920;

      const onResize = () => {
        const dpr = window.devicePixelRatio;

        const { innerWidth, innerHeight } = window;
        const ratio = innerHeight / innerWidth;

        const renderW = ratio < imgRatio ? innerWidth : innerHeight / imgRatio;
        const renderH = ratio < imgRatio ? innerWidth * imgRatio : innerHeight;

        canvas.value.width = renderW * dpr;
        canvas.value.height = renderH * dpr;

        canvas.value.style.width = `${renderW}px`;
        canvas.value.style.height = `${renderH}px`;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });
      const imgEl = await Promise.all([img.value, img1.value].map((el) => loadImg(el)));
      const draw = createBumpCommand(regl, imgEl, canvas.value);

      const bumpStart = () => {
        regl.frame(({ time }) => {
          draw({ time });
        });
      };

      bumpStart();
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, img1 };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'

.canvas
  display flex
  justify-content center
  align-items center
  canvas
    flex-shrink 0
</style>
